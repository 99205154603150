import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { colorPalette, Typo } from 'ri-components';
import { MD_LAYOUT_BREAKPOINT } from '../../themes/GlobalThemeProvider';
import qs from 'qs';
import { HandlerClaimStatusEnum } from '../../enums/HandlerClaimStatusEnum';
import { useTranslation } from 'react-i18next';

const { grey, teal } = colorPalette;

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    margin: '0 8px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up(MD_LAYOUT_BREAKPOINT)]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up(MD_LAYOUT_BREAKPOINT)]: {
      display: 'none',
    },
  },
  iconSmall: {
    height: 24,
    width: 24,
  },
  iconButton: {
    padding: 0,
    height: 32,
    width: 32,
    marginRight: '24px',
    '&:focus': {
      outline: 'none',
    },
  },
}));
const StyledMenu = withStyles((theme) => ({
  paper: {
    width: 180,
    borderRadius: 12,
    border: `solid 1px ${grey[3]}`,
    boxShadow: '0 4px 12px 0 #ddddde',
    [theme.breakpoints.down(MD_LAYOUT_BREAKPOINT)]: {
      width: '100%',
      maxWidth: '100%',
      borderRadius: 0,
      border: 'none',
      boxShadow: `0 12px 16px -8px ${grey[3]}`,
    },
  },
  list: {
    padding: theme.spacing(1.25, 0),
  },
}))(Menu);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: teal[10],
    fontSize: 15,
    lineHeight: 1.33,
    letterSpacing: '0.1px',
    padding: theme.spacing(1.25, 2.5),
  },
}))(MenuItem);

const claimListDefaultParams = getQueryParamsForClaimList();

const PageMenu = ({ navbarRef, claimListLabel }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(MD_LAYOUT_BREAKPOINT));
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleMenu = () => {
    setAnchorEl(navbarRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToDashboard = () => {
    history.push({ pathname: '/dashboard', search: claimListDefaultParams });
  };

  const highlightClaimListBttn = () => {
    return location.pathname.startsWith('/dashboard') || location.pathname.startsWith('/claim');
  };

  return (
    <>
      <div className={`${classes.sectionDesktop} `}>
        <Link className={classes.link} to={`/dashboard?${claimListDefaultParams}`}>
          <Typo
            variant='bodySm'
            fontWeight={highlightClaimListBttn() ? 'medium' : 'default'}
            color={highlightClaimListBttn() ? 'active' : 'default'}
            style={{ whiteSpace: 'nowrap' }}
          >
            
            {t('Claim List')}
          </Typo>
        </Link>
      </div>

      <div className={`${classes.sectionMobile} `}>
        {open !== true ? (
          <IconButton
            color='inherit'
            aria-label='open menu'
            disableRipple
            onClick={(event) => {
              handleMenu(event);
            }}
            className={classes.iconButton}
          >
            <MenuIcon className={classes.iconSmall} />
          </IconButton>
        ) : (
          <IconButton
            color='inherit'
            aria-label='open menu'
            onClick={(event) => {
              handleMenu(event);
            }}
            className={classes.iconButton}
          >
            <CloseIcon className={classes.iconSmall} />
          </IconButton>
        )}
        <StyledMenu
          id='menu-appbar'
          elevation={0}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          marginThreshold={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open && isMobile}
          onClose={handleClose}
        >
          <StyledMenuItem
            onClick={() => {
              handleClose();
              navigateToDashboard();
            }}
          >
            {claimListLabel}
          </StyledMenuItem>
        </StyledMenu>
      </div>
    </>
  );
};

function getQueryParamsForClaimList() {
  const urlParams = new URLSearchParams();
  urlParams.set('sort', 'submittedDate');
  urlParams.set('ascending', 'true');
  const filters = [
    { filterValues: [HandlerClaimStatusEnum.Open], filterFields: 'status' },
  ];
  urlParams.set('filters', qs.stringify(filters));
  return urlParams.toString();
}

PageMenu.propTypes = {
  navbarRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  claimListLabel: PropTypes.string,
};

export default PageMenu;
