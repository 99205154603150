import React, { Fragment, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Divider } from '@material-ui/core';
import SearchResultClaim from './SearchResultClaim';
import useHideScrollBarStyles from '../../themes/styles/hideScrollBar';
import { colorPalette, Typo } from 'ri-components';
import useInView, { isEmptySearch, isLastElement } from '../../hooks/useInView';
import clsx from 'clsx';
import { useNavSearch } from './useNavSearch';
import { useTranslation } from 'react-i18next';

const { white } = colorPalette;

const useStyles = makeStyles((theme) => ({
  claimResultModalContainer: {
    position: 'absolute',
    top: 'calc(100% + 2px)',
    left: ({ leftInputWidth }) => `${24 - leftInputWidth}px`,
    backgroundColor: white,
    zIndex: 6,
    maxHeight: '480px',
    padding: '4px 0px',
    borderRadius: '8px',
    boxShadow: '0 6px 8px 0 rgba(0, 0, 0, 0.5)',
    overflowY: 'scroll',
    width: 'calc(100vw - 48px)',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      left: '0px !important',
    },
  },
  divider: {
    margin: '0 20px',
  },
}));

function SearchResults({ searchBarText, isDisplayModal, onCloseModal, leftInputWidth }) {
  const { t } = useTranslation();
  const classes = useStyles({ leftInputWidth });
  const { hideScrollBar } = useHideScrollBarStyles();
  const containerRef = useRef(null);

  const { data: paginatedItems, fetchNextPage, isLoading, isFetchingNextPage } = useNavSearch(searchBarText);

  const onIntersecting = useCallback(() => {
    if (paginatedItems) {
      fetchNextPage();
    }
  }, [paginatedItems, fetchNextPage]);

  const { ref: lastNodeRef } = useInView({ isPreventing: isDisplayModal, onIntersecting });

  return (
    <ul role='listbox' ref={containerRef} className={clsx(classes.claimResultModalContainer, hideScrollBar)}>
      {paginatedItems?.pages?.map((page, pIdx) =>
        page?.result?.map((claim, idx) => (
          <Fragment key={claim.id}>
            <SearchResultClaim claim={claim} onCloseModal={onCloseModal} />
            {isLastElement(paginatedItems.pages, pIdx, page.result, idx) && (
              <Divider className={classes.divider} innerRef={lastNodeRef} />
            )}
          </Fragment>
        )),
      )}
      {isEmptySearch(paginatedItems) && (
        <Box my={2.5} textAlign='center'>
          <Typo variant='bodyMd' fontWeight='medium'>
            {t('No results for')} `{searchBarText}`
          </Typo>
        </Box>
      )}
      {(isFetchingNextPage || isLoading || searchBarText.length < 2) && (
        <Box textAlign='center' mt={2} aria-busy='true' aria-label='Loading...'>
          <CircularProgress />
        </Box>
      )}
    </ul>
  );
}

SearchResults.propTypes = {
  searchBarText: PropTypes.string.isRequired,
  isDisplayModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  leftInputWidth: PropTypes.number.isRequired,
};

export default React.memo(SearchResults);
